import PropTypes from 'prop-types'

import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'
import { handleTag } from '@saatva-bits/pattern-library.modules.detail-modal'

import { getArdadImages as getArdadImagesFunction } from '@/utils/product'

import productCodes from '@/temp-configs/product-codes'
import { slots } from '@/config/ardad-configs'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

const commonImageProps = {
    widths: { mobile: 348 },
    imgixDomain: IMGIX_URL,
    lazyLoad: true,
    /** Override the lazyload offset added by the detail product tile from bit  */
    lazyLoadOffset: null
}

const productsSort = [
    productCodes.classic,
    productCodes.saatvaRX,
    productCodes.latexHybrid,
    productCodes.contour5,
    productCodes.memoryFoamHybrid,
    productCodes.zenhaven
]

const ProductTileSet = ({ products }) => {
    const { getDiscounts } = useGetDiscounts()
    const { isMobile } = useBreakpoints('desktop')

    if (!products) {
        return null
    }

    // TODO: once test 'EXP.HP_MATTRESS_TILES.DIS-271' is complete, move this sort to the server side
    const sortedProducts = products
        .filter(({ productCode }) => productsSort.includes(productCode))
        .sort((productA, productB) => productsSort.indexOf(productA.productCode) - productsSort.indexOf(productB.productCode))

    const productList = isMobile ? sortedProducts.slice(0, 3) : sortedProducts

    return (
        <div className="row u-flexJustify--center">
            {productList.map(product => {
                const getImages = getArdadImagesFunction({ ...product, ...product.content }, slots.productTile, commonImageProps, true)
                const tag = handleTag(product.content.isNew, product.content.tag)
                const { finalPrice: discountedPrice } = getDiscounts([{
                    sku: product.productCode,
                    parentSku: product.productCode,
                    price: product.lowestPrice,
                    category: product.category.toLowerCase(),
                    quantity: 1,
                    isPrimaryProduct: true
                }])

                return (
                    <div key={product.productCode} className="col col--xs-12 col--sm-8 col--md-6 col--lg-4 u-marginBottom--2xl">
                        <DetailProductTile
                            title={{ text: product.content.title, href: product.url }}
                            subtitle={{ text: product.content.subtitle }}
                            priceRange={{
                                finalPriceMin: product.lowestPrice,
                                finalPriceMax: product.highestPrice
                            }}
                            imageHref={product.url}
                            affirm={{ price: discountedPrice }}
                            getImages={getImages}
                            imageBadgeLeft={tag && { text: tag }}
                        />
                    </div>
                )
            })}
        </div>
    )
}

ProductTileSet.propTypes = {
    products: PropTypes.array
}

export default ProductTileSet
