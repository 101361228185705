import Layout from '@/layouts/Default'

import HeroSection from '@/components/HeroSection/HeroSection'
import ValuePropsSection from '@/components/ValuePropsSection/ValuePropsSection'
import ProductTiles from '@/components/ProductTiles/ProductTiles'
import VideoSection from '@/components/VideoSection/VideoSection'
import CategoryTiles from '@/components/CategoryTiles/CategoryTiles'
import WhySaatva from '@/components/WhySaatva/WhySaatva'
import ReviewSection from '@/components/ReviewSection/ReviewSection'

export default function HomeView(props) {
    return (
        <Layout {...props}>
            <HeroSection {...props} />
            <ValuePropsSection />
            <ProductTiles products={props.products} />
            <VideoSection />
            <CategoryTiles />
            <WhySaatva />
            <ReviewSection reviews={props.reviewList} />
        </Layout>
    )
}
